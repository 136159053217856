// controllers/image_video_controller.js
import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [
    "contentTypeInputField",
    "imageWrapper",
    "imageInputField",
    "imagePreviewBox",
    "videoWrapper",
    "videoSourceSelect",
    "videoUrlField",
    "videoUploadField",
  ];

  connect() {
    // this.resetFields();
  }

  content_type_change(event) {
    const contentType = event.target.value;
    this.toggleContentType(contentType);
  }

  toggleContentType(contentType) {
    // this.resetFields();

    if (contentType === "image") {
      this.imageWrapperTarget.classList.remove("d-none");
      this.imageInputFieldTarget.required = true;
      this.imagePreviewBoxTarget.innerHTML = "";

      this.clearVideoFields(); // Clear video fields if image is selected
      this.videoWrapperTarget.classList.add("d-none");
      this.videoSourceSelectTarget.classList.add("d-none");
      this.videoSourceSelectTarget.required = false;
      this.videoUploadFieldTarget.required = false;
      this.videoUrlFieldTarget.required = false;
    } else if (contentType === "video") {
      this.videoWrapperTarget.classList.remove("d-none");
      this.videoSourceSelectTarget.classList.remove("d-none");
      this.videoSourceSelectTarget.required = true;
      this.clearImageField(); // Clear image field if video is selected
      this.imageWrapperTarget.classList.add("d-none");
      this.imageInputFieldTarget.required = false;
      this.handleVideoSourceChange({ target: this.videoSourceSelectTarget });
    }
  }

  handleVideoSourceChange(event) {
    const selectedSource = event.target.value;
    this.videoUploadFieldTarget.required = false;
    this.videoUrlFieldTarget.required = false;

    if (selectedSource === "Upload") {
      this.videoUploadFieldTarget.classList.remove("d-none");
      this.videoUploadFieldTarget.required = true;
      this.videoUrlFieldTarget.classList.add("d-none");
    } else if (selectedSource === "Youtube" || selectedSource === "Vimeo") {
      this.videoUrlFieldTarget.classList.remove("d-none");
      this.videoUrlFieldTarget.required = true;
      this.videoUrlFieldTarget.placeholder = `Enter ${selectedSource} URL`;
      this.videoUploadFieldTarget.classList.add("d-none");
    } else {
      this.videoUrlFieldTarget.classList.add("d-none");
      this.videoUploadFieldTarget.classList.add("d-none");
    }
  }

  resetFields() {
    // Hide and reset all fields initially
    this.imageWrapperTarget.classList.add("d-none");
    this.videoWrapperTarget.classList.add("d-none");

    this.imageInputFieldTarget.required = false;
    this.videoSourceSelectTarget.required = false; // Reset video source required
    this.videoUploadFieldTarget.required = false;
    this.videoUrlFieldTarget.required = false;

    this.videoUploadFieldTarget.classList.add("d-none");
    this.videoUrlFieldTarget.classList.add("d-none");
  }

  clearImageField() {
    this.imageInputFieldTarget.value = null;
  }

  clearVideoFields() {
    this.videoUploadFieldTarget.value = null;
    this.videoUrlFieldTarget.value = null;
  }

  previewImage(event) {
    const file = event.target.files[0];
    if (file) {
      const preview = document.createElement("img");
      preview.src = URL.createObjectURL(file);
      preview.style.width = "100%";
      preview.style.objectFit = "contain";
      this.imagePreviewBoxTarget.replaceChildren(preview);
    }
  }
}
