import { Controller } from "stimulus";

export default class extends Controller {
  preventNegative(event) {
    if (event.key === "-") {
      event.preventDefault();
    }
  }

  validateAmount() {
    if (this.element.value < 0) {
      this.element.value = Math.abs(this.element.value);
    }
  }
}
